import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

import { navigate } from 'gatsby'
import SubMenuButton from './navbar/subMenuButton'

export default function Navbar({
  setNavState,
}: {
  setNavState: (navState: boolean) => void
}): JSX.Element {
  const [menuActive, setMenuActive] = React.useState(false)
  const [subMenuActive, setSubMenuActive] = React.useState(false)
  const [subMenuID, setSubMenuID] = React.useState(-1)

  function sleep(ms: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const useIsMount = (): unknown => {
    const isMountRef = React.useRef(true)
    React.useEffect(() => {
      isMountRef.current = false
    }, [])
    return isMountRef.current
  }

  const isMount = useIsMount()

  React.useEffect(() => {
    if (!isMount) {
      setNavState(menuActive)

      const navbar = document.getElementById('navbar') as HTMLElement
      const imageWrapper = document.getElementById('logoWrapper') as HTMLDivElement
      const aboutUsButton = document.getElementById('aboutUsButton') as HTMLUListElement
      const title = document.getElementById('title') as HTMLDivElement

      if (menuActive === true) {
        imageWrapper.classList.add('hidden')
        aboutUsButton.classList.add('hidden')

        setTimeout(() => {
          navbar.style.display = 'block'
          title.className = ''
          title.classList.add('animateTitleEntrance')

          imageWrapper.classList.add('displayHidden')
          aboutUsButton.classList.add('displayHidden')
        }, 750)
      } else {
        title.className = ''
        title.classList.add('animateTitleExit')

        sleep(50).then(() => {
          title.classList.add(...['hidden', 'displayHidden'])
          navbar.style.display = ''
          imageWrapper.classList.remove('displayHidden')
          aboutUsButton.classList.remove('displayHidden')
        })

        sleep(200).then(() => {
          imageWrapper.classList.remove('hidden')
          aboutUsButton.classList.remove('hidden')
        })

        setSubMenuActive(false)
      }
    }
  }, [setNavState, menuActive, isMount])

  function setSubMenu(ID: number): void {
    if (ID !== subMenuID) {
      setSubMenuActive(true)
      setSubMenuID(ID)
    } else {
      setSubMenuActive(false)
      setSubMenuID(-1)
    }
  }

  function changePage(destURL: string): void {
    setMenuActive(false)
    setSubMenu(subMenuID)
    navigate(destURL)
  }

  return (
    <nav id="navbar" role="navigation" className={`${menuActive ? 'activeMenu' : 'closeMenu'}`}>
      <ul>
        <li className="hamburger">
          <div
            role="button"
            tabIndex={0}
            className={`plate plate7 ${menuActive ? 'active' : ''}`}
            onClick={() => setMenuActive(!menuActive)}
            onKeyDown={() => setMenuActive(!menuActive)}
            aria-label="Toggle Navigation Menu"
          >
            <svg className="burger" version="1.1" viewBox="25 0 100 100">
              <path
                className="line line1"
                d="M 30,35 H 60 C 63.595663,35 65,32.357023 65,30 C 65,27.642977 62.357023,25 60,25 C 57.642977,25 55,25.933659 55,30 V 77.828008"
              />
              <path
                className="line line2"
                d="M 70,35 H 50 C 46.404337,35 45,32.357023 45,30 C 45,27.642977 47.642977,25 50,25 C 52.357023,25 55,25.933659 55,30 V 77.828008"
              />
              <path
                className="line line3"
                d="M 30,50 H 55 C 58.595665,50 60.000005,47.357023 60.000005,45 C 60.000005,42.642977 57.357025,40 55,40 C 52.642977,40 50,40.933659 50,45 V 92.828008"
              />
              <path
                className="line line4"
                d="M 70,50 H 45 C 41.404337,50 40,47.357023 40,45 C 40,42.642977 42.642977,40 45,40 C 47.357023,40 50,40.933659 50,45 V 92.828008"
              />
              <path
                className="line line5"
                d="M 30,65 H 50 C 53.595665,65 55.000005,62.357023 55.000005,60 C 55.000005,57.642977 52.357025,55 50,55 C 47.642977,55 45,55.933659 45,60 V 107.82801"
              />
              <path
                className="line line6"
                d="M 70,65 H 40 C 36.404337,65 35,62.357023 35,60 C 35,57.642977 37.642977,55 40,55 C 42.357023,55 45,55.933659 45,60 V 107.82801"
              />
            </svg>
            <svg className="x" version="1.1" viewBox="25 0 100 100">
              <path className="line" d="M 34,32 L 66,68" />
              <path className="line" d="M 66,32 L 34,68" />
            </svg>
          </div>
        </li>
      </ul>
      <div id="logoWrapper" className="logoWrapper menuItemAnimate">
        <StaticImage
          src="../images/icons/logo.png"
          alt="Youth Genes Logo"
          placeholder="blurred"
          onClick={() => {
            changePage('/')
          }}
          aspectRatio={2 / 1}
          loading="eager"
        />
      </div>
      <ul id="aboutUsButton" className="menuItemAnimate">
        <li>
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              navigate('/aboutus')
            }}
            onKeyDown={() => {
              navigate('/aboutus')
            }}
          >
            <h5>About Us</h5>
          </div>
        </li>
      </ul>
      <div id="title" className="hidden displayHidden">
        <a
          role="button"
          tabIndex={0}
          onClick={() => {
            setSubMenu(0)
          }}
          onKeyDown={() => {
            setSubMenu(0)
          }}
          aria-label="Pathway into Genetics"
        >
          <h2>Pathway into Genetics</h2>
        </a>
        <div
          className={`subMenu ${subMenuActive && subMenuID === 0 ? '' : 'hidden displayHidden'}`}
        >
          <SubMenuButton buttonText="Courses" callback={() => changePage('/courses')} />
          <SubMenuButton buttonText="Careers" callback={() => changePage('/careers')} />
          <SubMenuButton buttonText="Experiences" callback={() => changePage('/experiences')} />
        </div>
        <a
          role="button"
          tabIndex={0}
          onClick={() => {
            setSubMenu(1)
          }}
          onKeyDown={() => {
            setSubMenu(1)
          }}
          aria-label="Basics about Genetics"
        >
          <h2>Basics about Genetics</h2>
        </a>
        <div
          className={`subMenu ${subMenuActive && subMenuID === 1 ? '' : 'hidden displayHidden'}`}
        >
          <SubMenuButton
            buttonText="Genetic Inheritance"
            callback={() => changePage('/inheritance')}
          />
          <SubMenuButton buttonText="Genetic Diseases" callback={() => changePage('/diseases')} />
        </div>
        <a
          role="button"
          tabIndex={0}
          onClick={() => {
            changePage('/conversation')
          }}
          onKeyDown={() => {
            changePage('/conversation')
          }}
          aria-label="Pathway into Genetics"
        >
          <h2>Talking about Genetics</h2>
        </a>
        <a
          role="button"
          tabIndex={0}
          onClick={() => {
            setSubMenu(2)
          }}
          onKeyDown={() => {
            setSubMenu(2)
          }}
          aria-label="About Youth Genes"
        >
          <h2>About Youth Genes</h2>
        </a>
        <div
          className={`subMenu ${subMenuActive && subMenuID === 2 ? '' : 'hidden displayHidden'}`}
        >
          <SubMenuButton buttonText="About Youth Genes" callback={() => changePage('/aboutus')} />
          <SubMenuButton buttonText="Meet the Team" callback={() => changePage('/team')} />
        </div>
      </div>
    </nav>
  )
}
