import * as React from 'react'

export default function ConversationCard({
  children,
  stepNum,
  title,
}: {
  children: JSX.Element[]
  stepNum: number
  title: string
}): JSX.Element {
  return (
    <div className="conversationCard">
      <h4>
        Step {stepNum}: {title}
      </h4>
      <div>{children}</div>
    </div>
  )
}
