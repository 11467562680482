import * as React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './footer'
import Navbar from './navbar'

import 'preact/debug'

import '../styles/index.scss'

const globalExperimentalFeatures = process.env.GATSBY_ENABLE_EXPERIMENT_FEATURES === 'true'

export const ExperimentalFeatures = React.createContext(globalExperimentalFeatures)

export default function Layout({ children }: { children: JSX.Element }): JSX.Element {
  const [navIsOpen, setNavState] = React.useState(false)
  const [experimentalFeatures, setExperimentalFeatures] = React.useState(globalExperimentalFeatures)

  function changeNavState(newState: boolean): void {
    setNavState(newState)
  }

  return (
    <ExperimentalFeatures.Provider value={experimentalFeatures}>
      <div id="layout" role="main" className={`${navIsOpen ? 'contentOverflow' : ''}`}>
        <Helmet title="Youth Genes" defer={false} htmlAttributes={{ lang: 'en' }}>
          <meta
            name="description"
            content="Have you wondered what makes you, yourself? It all has to do with genes, curious to learn more?"
          />
        </Helmet>
        <Navbar setNavState={changeNavState} />
        <div id="content">{children}</div>
        <Footer />
        {globalExperimentalFeatures && (
          <button
            type="button"
            onClick={() => {
              setExperimentalFeatures(!experimentalFeatures)
            }}
          >
            {experimentalFeatures.toString()}
          </button>
        )}
      </div>
    </ExperimentalFeatures.Provider>
  )
}
