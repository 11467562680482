// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-basics-tsx": () => import("./../../../src/pages/basics.tsx" /* webpackChunkName: "component---src-pages-basics-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-diseases-tsx": () => import("./../../../src/pages/diseases.tsx" /* webpackChunkName: "component---src-pages-diseases-tsx" */),
  "component---src-pages-experiences-tsx": () => import("./../../../src/pages/experiences.tsx" /* webpackChunkName: "component---src-pages-experiences-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inheritance-tsx": () => import("./../../../src/pages/inheritance.tsx" /* webpackChunkName: "component---src-pages-inheritance-tsx" */),
  "component---src-pages-interactive-genetree-tsx": () => import("./../../../src/pages/interactive/genetree.tsx" /* webpackChunkName: "component---src-pages-interactive-genetree-tsx" */),
  "component---src-pages-mdx-slug-tsx": () => import("./../../../src/pages/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-mdx-slug-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

