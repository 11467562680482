import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

export default function Footer(): JSX.Element {
  const [errorString, setErrorString] = React.useState('')
  const [submission, setSubmission] = React.useState(false)
  const [submitString, setSubmitString] = React.useState('Submit')

  function submitNewsletterForm(e: React.SyntheticEvent): void {
    e.preventDefault()

    const target = e.target as typeof e.target & {
      email: { value: string }
      name: { value: string }
    }

    const email = target.email.value
    const name = target.name.value

    setErrorString('')
    setSubmission(true)
    setSubmitString('Submitting...')

    const xhr = new XMLHttpRequest()
    xhr.open('POST', 'https://api.youthgenes.org/api/email', true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const data = JSON.parse(xhr.responseText)
        if (data.error === undefined) {
          setSubmitString('Success')
        } else {
          setTimeout(() => {
            setSubmitString('Submit')
            setErrorString(data.error)
            setSubmission(false)
          }, 1000)
        }
      }
    }
    xhr.send(
      JSON.stringify({
        email,
        name,
      })
    )
  }

  return (
    <div id="footer">
      <div className="newletterForm">
        <h3>Keep Up to Date</h3>
        <h4>
          Sign up for our monthly newsletter with updates on new study guides and other Youth Genes
          updates.
        </h4>
        <form onSubmit={submitNewsletterForm}>
          <div className="inputWrapper">
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              onChange={() => {
                setErrorString('')
              }}
            />
            <input
              type="name"
              name="name"
              placeholder="Name"
              onChange={() => {
                setErrorString('')
              }}
            />
          </div>
          <h5 className={`${errorString === '' ? 'hidden' : ''}`}>{errorString}</h5>
          <button type="submit" aria-disabled={submission} disabled={submission}>
            {submitString}
          </button>
        </form>
      </div>
      <div className="socialMedia">
        <p>Follow us on Social Media:</p>
        <StaticImage
          className="socialMediaIcon"
          src="../images/icons/svgs/instagram.svg"
          alt="Instagram Logo"
          width={30}
          height={30}
          onClick={() => window.open('https://www.instagram.com/youthgenes/', '_blank')}
        />
      </div>
      <p>v2.0b1 - Copyright© 2021 Youth Genes</p>
    </div>
  )
}
