import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { BannerInterface } from './interfaces'

export default function Banner({
  bannerImage,
  bannerText,
  textColor,
}: BannerInterface): JSX.Element {
  function getBannerImage(): JSX.Element {
    switch (bannerImage) {
      case 'Article':
        return (
          <StaticImage
            src="../images/banners/ArticleBanner.png"
            alt={bannerText}
            placeholder="dominantColor"
          />
        )
      case 'Career':
        return (
          <StaticImage
            alt="Career"
            src="../images/banners/CareerBanner.png"
            placeholder="dominantColor"
          />
        )
      case 'Conversation':
        return (
          <StaticImage
            alt="Conversation"
            src="../images/banners/ConversationBanner.png"
            placeholder="dominantColor"
            style={{ position: 'static' }}
          />
        )
      case 'Diseases':
        return (
          <StaticImage
            alt="Genetic Diseases"
            src="../images/banners/DiseasesBanner.png"
            placeholder="dominantColor"
            style={{ position: 'static' }}
          />
        )
      case 'Experiences':
        return (
          <StaticImage
            alt="Experiences"
            src="../images/banners/ExperiencesBanner.png"
            placeholder="dominantColor"
            style={{ position: 'static' }}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <div className="banner">
      {getBannerImage()}
      <div className="textContainer">
        <h1 className={`${textColor}Text`}>{bannerText}</h1>
      </div>
    </div>
  )
}
