import React from 'react'

export default function SubMenuButton({
  buttonText,
  callback,
}: {
  buttonText: string
  callback: () => void
}): JSX.Element {
  return (
    <a
      role="button"
      tabIndex={0}
      onClick={() => {
        callback()
      }}
      onKeyDown={() => {
        callback()
      }}
      aria-label={buttonText}
    >
      <h4>{buttonText}</h4>
    </a>
  )
}
